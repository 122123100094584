import { motion } from 'framer-motion';
import { useState } from 'react';
import dropDownWhite from './DropdownWhite.png'

function Project2(){



    const [isOpen, setIsOpen] = useState(false);

    const container = {
        closed: {
          opacity: 0,
          height: 0,
          transition: {
            duration: 0.5,
    
          }
        },
        open: {
          opacity: 1,
          height: '220px',
          transition: {
            duration: 1.5,
    
          }
        }
      }
    
      const list = {
        closed: {
          opacity: 0,
        },
        open: {
          opacity: 1,
          transition: {
            duration: 1.5,
            delayChildren: 0.7,
            staggerChildren: 0.5
    
          }
        }
    
      }
    
      const item = {
        closed: { opacity: 0 },
        open: {
          opacity: 1,
    
        }
      }
      

      
      return(


    <div className="container projectContainer">
          <div id='nhl' className="centerText centerDiv headerDiv projectHeader">
            <motion.img className="dropDown" src={dropDownWhite} onClick={() => { !isOpen ? setIsOpen(true) : setIsOpen(false) }}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              animate={isOpen ? { rotate: 180, transition: { duration: 0.5 } } : { rotate: 0 }}
            ></motion.img>
            <a href="http://edwinring.website/nhl-comparison-tool"  target="_blank" rel="noreferrer" class="projectName"><motion.p  whileHover={{ scale: 1.1 }} class="projectName" id="nhlHeader">NHL Comparison Tool</motion.p></a>
          </div>
          <motion.div className="projectDiv centerDiv" id='project1'
            initial={{ height: 0, opacity: 0 }}
            animate={!isOpen ? 'closed' : 'open'}
            variants={container}
          >
            <motion.ul className='projectList'
              animate={!isOpen ? 'closed' : 'open'}
              variants={list}
            >
              <motion.li variants={item} className="fontify listEle">Developed with React</motion.li>
              <motion.li variants={item} className="fontify listEle">Implements official NHL API for most features</motion.li>
              <motion.li variants={item} className="fontify listEle">Generates representative cards for comparative analysis</motion.li>
              <motion.li variants={item} className="fontify listEle">Autofill select elements based on previous choices</motion.li>
         

            </motion.ul>

          </motion.div>
        </div>

      );

}

export default Project2