import { motion } from 'framer-motion';
import { useState } from 'react';
import dropDownWhite from './DropdownWhite.png'


function Project4() {


    const [isOpen, setIsOpen] = useState(false);

    const container = {
        closed: {
          opacity: 0,
          height: 0,
          transition: {
            duration: 0.5,
    
          }
        },
        open: {
          opacity: 1,
          height: '220px',
          transition: {
            duration: 1.5,
    
          }
        }
      }
    
      const list = {
        closed: {
          opacity: 0,
        },
        open: {
          opacity: 1,
          transition: {
            duration: 1.5,
            delayChildren: 0.7,
            staggerChildren: 0.5
    
          }
        }
    
      }
    
      const item = {
        closed: { opacity: 0 },
        open: {
          opacity: 1,
    
        }
      }

      
      return(


    <div className="container projectContainer">
          <div id='stockfriend' style = {{backgroundColor: 'rgb(208, 96, 193)'}}className="centerText centerDiv headerDiv projectHeader">
            <motion.img className="dropDown" src={dropDownWhite} onClick={() => { !isOpen ? setIsOpen(true) : setIsOpen(false) }}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              animate={isOpen ? { rotate: 180, transition: { duration: 0.5 } } : { rotate: 0 }}
            ></motion.img>
            <a href="http://www.edwinring.website/2048" target="_blank" rel="noreferrer" class="projectName"><motion.p class="projectName" whileHover={{ scale: 1.05 }} id="stockfriendHeader">2048</motion.p></a>
          </div>
          <motion.div className="projectDiv centerDiv" id='project1'
            initial={{ height: 0, opacity: 0 }}
            animate={!isOpen ? 'closed' : 'open'}
            variants={container}
          >
            <motion.ul className='projectList'
              animate={!isOpen ? 'closed' : 'open'}
              variants={list}
            >
              <motion.li variants={item} className="fontify listEle">Developed using only JavaScript</motion.li>
              <motion.li variants={item} className="fontify listEle">Implements many common JavaScript procedures</motion.li>
              <motion.li variants={item} className="fontify listEle">Developed with MVC design pattern</motion.li>
              <motion.li variants={item} className="fontify listEle">Demonstrates my proficiency in JavaScript, outside of React</motion.li>
            </motion.ul>
          </motion.div>
        </div>

      );

}

export default Project4