import logo from './logo.svg';
import './App.css';
import 'bulma/css/bulma.min.css';
import { motion } from 'framer-motion';
import { useState } from 'react';
import dropDownWhite from './DropdownWhite.png'
import Project from "./Project";
import Project1 from './Project1';
import Project2 from './Project2';
import Project3 from './Project3';
import Project4 from './Project4';
import Project5 from './Project5';
import AboutMe from './AboutMePage';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation
} from "react-router-dom";
import { useEffect } from "react";

function App() {

  return (

    <Router>
      <div id="test">
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/about-me" element={<AboutMe />} />
        </Routes>
      </div>
    </Router>

  );

  
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function Homepage(){

  return (

    <div id='body'>

      <div id="headDiv">
        <div style={{ position: 'relative', left: '20px', top: '15px' }}>
          <p className='fontify headerName'>
            Win Ring
          </p>
          <p className='fontify headerTitle'>Software Developer</p>
        </div>

        <nav style={{ position: 'relative', left: '85%', bottom: '40px', color: 'black' }}>
          <a id="nav_a" target="_blank" style={{ fontSize: '20px' }} href="https://acrobat.adobe.com/link/track?uri=urn:aaid:scds:US:479d1521-fe23-4f73-aa53-8007aa2e4897">Resume</a>
          <span style={{ color: 'black', fontSize: '20px' }}> | </span>
       
          <Link to={"about-me"} className='fontify' id="navLink" style={{ color: 'black', fontSize: '20px' }}>About Me</Link>
        </nav>

      </div>

      <br />



      <p>*This website is fully functional, but stylistically in development*</p>

      <div id="projects" className="centerDiv">
        <div id="introduction" className="fontify centerText centerDiv">
          Projects
        </div>
        <br />

        <Project5 />

        <Project1 />

        <Project2 />

        <Project3 />

        <Project4 />


      </div>
    </div>
  );
}

export default App;
