import { motion } from 'framer-motion';
import { useState } from 'react';
import dropDownWhite from './DropdownWhite.png'
import enjoyhomecarepic from './EnjoyHomeCareLogo.png';


function Project5() {


    const [isOpen, setIsOpen] = useState(false);

    const container = {
        closed: {
          opacity: 0,
          height: 0,
          transition: {
            duration: 0.5,
    
          }
        },
        open: {
          opacity: 1,
          height: '220px',
          transition: {
            duration: 1.5,
    
          }
        }
      }
    
      const list = {
        closed: {
          opacity: 0,
        },
        open: {
          opacity: 1,
          transition: {
            duration: 1.5,
            delayChildren: 0.7,
            staggerChildren: 0.5
    
          }
        }
    
      }
    
      const item = {
        closed: { opacity: 0 },
        open: {
          opacity: 1,
    
        }
      }

      
      return(


    <div className="container projectContainer">
          <div id='enjoyHomeCare' className="centerText centerDiv headerDiv">
            <motion.img className="dropDown" src={dropDownWhite} onClick={() => { !isOpen ? setIsOpen(true) : setIsOpen(false) }}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              animate={isOpen ? { rotate: 180, transition: { duration: 0.5 } } : { rotate: 0 }}
            ></motion.img>
            <a href="http://www.enjoyhomecare.com" target="_blank" rel="noreferrer"><b className="projectName" id="stockfriendHeader"><motion.img src={enjoyhomecarepic} whileHover={{ scale: 1.05 }} id="enjoyhomecarelogo"></motion.img></b></a>
          </div>
          <motion.div className="projectDiv centerDiv" id='project1'
            initial={{ height: 0, opacity: 0 }}
            animate={!isOpen ? 'closed' : 'open'}
            variants={container}
          >
            <motion.ul className='projectList'
              animate={!isOpen ? 'closed' : 'open'}
              variants={list}
            >
              <motion.li variants={item} className="fontify listEle">Developed with React</motion.li>
              <motion.li variants={item} className="fontify listEle">Homepage of a Milwaukee homecare business</motion.li>
              <motion.li variants={item} className="fontify listEle">Full-stack, with a fully functional RESTfulAPI backend developed using NodeJS</motion.li>
              <motion.li variants={item} className="fontify listEle">Database implemented with SQLite, hosted on Amazon Web Services EC2</motion.li>
              <motion.li variants={item} className="fontify listEle">Mobile-friendly and responsive</motion.li>
            </motion.ul>

          </motion.div>
        </div>

      );

}

export default Project5