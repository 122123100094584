
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from "react-router-dom";

import profilePicture from "./profilePicture.jpeg";

function AboutMe() {


    return (

        <div id="aboutMeDiv">

            <div id="aboutMeStyleDiv">
            </div>
            <div id="aboutMeStyleDiv2">

            </div>

                <img src={profilePicture} id="profilePicture"></img>
                <h1 id="aboutMeH1">Hi, I'm Win!</h1>

                <div id="aboutMeInfo">

                    <p>
                        I love competition, sports, games, and music. Oh, and of course, developing things!
                    </p>

                </div>
                <div id="aboutMeText">

                    <p>

                        I am a software developer who specializes in full-stack web development using React coupled with Node.js; however, I am open to (and actively)
                        learning new technologies. I have created multiple websites, two of which are full-stack, and they can all be accessed from this website!
                        
                        <br></br>
                        <br></br>
                        I love thinking about questions and problems.
                        In fact, I originally intended to major in philosophy! However, I also love creating things, and once I discovered programming I never looked back.
                        I graduated from UNC Chapel Hill in December 2021 with B.A. in computer science. I have no problem admitting that I
                        have a lot to learn, but you won't find anyone more eager to do so.
                        I truly care about the work that I do, and I always put in my best effort.
                        I’m excited to make a real difference using the skills that I have worked so hard to develop over the past few years. 

                    </p>

                </div>

        </div>
    )



}

export default AboutMe